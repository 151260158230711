export const bfcPackage = [
    {
        value: "Non - Extraction 35,000 (Lab Fee 20,000)",
        text: "Non - Extraction 35,000",
        price: 35000,
        labFee: 20000
    },
    {
        value: "Extraction / Distalization 45,000 (Lab Fee 30,000)",
        text: "Extraction / Distalization 45,000",
        price: 45000,
        labFee: 30000
    },
    {
        value: "Non - Extraction U/L 17,500 (Lab Fee 10,000)",
        text: "Non - Extraction U/L 17,500",
        price: 17500,
        labFee: 10000
    },
    {
        value: "Extraction / Distalization U/L 27,500 (Lab 15,000)",
        text: "Extraction / Distalization U/L 27,500",
        price: 27500,
        labFee: 15000
     }
    //,
    // {
    //     value: "Influencer Package ( 0 Baht )",
    //     text: "Influencer Package ( 0 Baht )",
    //     price: 0,
    //     labFee: 0
    // }
]

export const r3Package = [
    {
        value: "U/L only 5000 (Lab fee 2,500)",
        text: "U/L only 5000 THB",
        price: 5000,
        labFee: 2500
    },
    {
        value: "U+L 10,000 (Lab fee 5,000)",
        text: "U+L 10,000 THB",
        price: 10000,
        labFee: 5000
    },
   
]

export const depositCurrency = {
    THB: 3000,
    USD : 100
}



export const attachmentCfg = {
    type:['Bevel-Attachment','2-Bevel-Attachment','Horizontal-Rectangular-Attachment','Semi-Elliptical-Attachment-L','Semi-Elliptical-Attachment-R','Vertical-Rectangular-Attachment'],
    name:['Bevel','2-Bevel','Horizontal Rectangular','Left Semi Elliptical','Rigth Semi Elliptical','Vertical Rectangular'],
    size:['Small','Middle','Large'],
    color:[
        {
        size: "Small",
        code:"#f89e0e"
    },
     {   
         size: "Medium",
         code:"#94c444"
    },
      {  
          size:"Large",
          code:"#0979cc"
    }]
}

export const twoBevelTeethNo = [16,17, 18, 26, 27, 28, 36, 37, 38, 46, 47, 48]