export const nullOrUndefined = (value) => {
    //console.log(typeof value)
    let returnValue;
    if (typeof value !== "undefined") {
        returnValue = value;
    } else {
        returnValue = ""; // empty
    }
    //console.log(returnValue)
    return returnValue;
}

export const isAttachmentExists = (teethAttachment,teethNo) => {
    //console.log(typeof teethAttachment)
    return teethAttachment.some((item) => {
        return item.teethNo == teethNo
    })
}

export const keyExists = (_Array,_Key) => {
    //----------------------------------//
    return _Array.some(item =>{
        return (typeof item[_Key] !== "undefined") ? true:false;
    })   
}