<template>
  <v-dialog v-model="attachmentDialog" max-width="960px" persistent
    ><template>
      <v-container>
        <v-card
          ><v-card-text>
            <div>
              <p>Click on image to select!</p>
            </div>
            <v-list class="attachmentTeeth">
              <v-list-item v-for="(name, index) in attachmentCfg.name" :key="index">
                <v-list-item-content two-line>
                  <v-list-item-title class="text-left">{{ name }} :</v-list-item-title>
                  <v-list-item-subtitle
                    ><div>
                      <img
                        v-for="size in attachmentCfg.size"
                        :key="size"
                        :src="checkExistTeeth(index, teethNo, size)"
                        loading="lazy"
                        alt=""
                        :class="
                          selectedTeeth ==
                          size + '-' + attachmentCfg.type[index] + '-' + teethNo + '.png'
                            ? 'selectedTeeth'
                            : ''
                        "
                        @click="
                          selectTeeth(
                            'attachments/' +
                              size +
                              '/' +
                              size +
                              '-' +
                              attachmentCfg.type[index] +
                              '/',
                            size +
                              '-' +
                              attachmentCfg.type[index] +
                              '-' +
                              teethNo +
                              '.png'
                          )
                        "
                      /></div
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content two-line>
                  <v-list-item-title class="text-left">None :</v-list-item-title>
                  <v-list-item-subtitle
                    ><div>
                      <img
                        :src="
                          require('../../assets/' + 'teeth/teeth-' + teethNo + '.png')
                        "
                        loading="lazy"
                        alt=""
                        :class="
                          selectedTeeth == 'teeth-' + teethNo + '.png'
                            ? 'selectedTeeth'
                            : ''
                        "
                        @click="selectTeeth('teeth/', 'teeth-' + teethNo + '.png')"
                      /></div
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text>
            <ul>
              <li v-for="color in attachmentCfg.color" :key="color">
                <div
                  class="box"
                  :style="'background-color:' + color.code + ';padding-left:10px'"
                >
                  {{ color.size }}
                </div>
              </li>
            </ul></v-card-text
          >

          <v-card-actions> <v-btn @click="close">Close</v-btn></v-card-actions>
        </v-card>
      </v-container>
    </template>
  </v-dialog>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { attachmentCfg, twoBevelTeethNo } from "../../config/config";
export default {
  name: "teethAttachment",
  props: {
    show: {
      type: Boolean,
    },
    teethNo: { type: Number },
    attachmentData: {
      type: Object,
    },
  },

  data() {
    return {
      attachmentDialog: false,
      attachmentCfg: attachmentCfg,
      selectedTeeth: "",
    };
  },
  mounted() {
    // console.log("mounted attachment dialog");
    // console.log(this.show);
    // console.log(this.attachmentCfg.name);

    this.attachmentDialog = this.show;
    this.selectedTeeth = this.attachmentData
      .filter((item) => {
        return item.teethNo == this.teethNo;
      })
      .map((item) => {
        return item.imgName;
      });
  },
  methods: {
    close() {
      this.attachmentDialog = false;
      this.$emit("closeAttachment");
    },
    checkExistTeeth(index, no, size) {
      console.log(index + "  " + size + "  " + no);
      console.log(attachmentCfg.type[index]);

      let teeth = "";
      switch (attachmentCfg.type[index]) {
        case "2-Bevel-Attachment":
          if (twoBevelTeethNo.indexOf(no) > -1) {
            teeth = require("../../assets/attachments/" +
              size +
              "/" +
              size +
              "-" +
              attachmentCfg.type[index] +
              "/" +
              size +
              "-" +
              attachmentCfg.type[index] +
              "-" +
              no +
              ".png");
          } else {
            teeth = "";
          }

          break;

        default:
          teeth = require("../../assets/attachments/" +
            size +
            "/" +
            size +
            "-" +
            attachmentCfg.type[index] +
            "/" +
            size +
            "-" +
            attachmentCfg.type[index] +
            "-" +
            no +
            ".png");
          break;
      }
      return teeth;
    },

    selectTeeth(_selectedPath, _selectedTeeth) {
      this.selectedTeeth = _selectedTeeth;
      //console.log(_selectedPath + "   " + _selectedTeeth);
      let attachmentTeeth = {
        path: _selectedPath,
        imgName: _selectedTeeth,
        teethNo: this.teethNo,
      };
      this.$emit("selectAttachmentTeeth", attachmentTeeth);
    },
  },

  watch: {
    show: {
      handler(value) {
        //console.log("show " + value);
        this.attachmentDialog = value;
      },
    },
  },
};
</script>
<style type="css" scope>
.v-list-item__subtitle {
  flex-direction: row !important;
  -ms-flex-direction: row !important;
}
.attachmentTeeth img {
  max-width: 72px;
  cursor: pointer;
}
.attachmentTeeth img:hover,
.selectedTeeth {
  border: 2px solid;
  max-width: 96px;
  border-color: #fb8c00;
}
.box {
  width: 100px;
  height: 32px;
  display: block;
}
</style>
