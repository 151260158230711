<template>
  <div
    class="upload"
    v-cloak
    @drop.prevent="handleImage"
    @dragover.prevent
    :id="uploadId"
  >
    <p v-if="imgUrl[uploadId] == ''">{{ uploadName }}</p>
    <div ref="img" style="position: relative" v-else>
      <v-fab-transition v-if="!isEmptyUrl">
        <v-btn icon dark absolute top right color="red" fab small @click="removeImage"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-fab-transition>
      <img :src="file.FileValue" loading="lazy" />
    </div>
    <v-file-input
      v-model="image"
      accept="image/png, image/jpeg, image/bmp"
      prepend-icon="mdi-camera"
      :label="uploadName"
      @change="uploadInputImage"
      :disabled="disabledUpload"
    ></v-file-input>
  </div>
</template>
<script>
export default {
  name: "dragdropdUpload",
  props: {
    disabledUpload: {
      type: Boolean,
      default: false,
    },
    uploadName: {
      type: String,
    },
    uploadId: {
      type: String,
    },
    imgData: [String, Number, Object, undefined],
    imgUrl: {
      type: Object,
    },
    totalStep: {
      type: Number,
    },
    stepToComplete: {
      type: String,
    },
  },
  data() {
    return {
      time: new Date().getTime(),
      url: process.env.VUE_APP_LOYALTY_API_URI,
      image: "",
      file: {
        FileName: "",
        FileType: "",
        FileValue: "",
        IsActive: "",
      },
      uploadImage: "",
      isEmptyUrl: true,
    };
  },
  computed: {
    // isEmptyUrl() {
    //   // console.log(Object.keys(this.imgUrl[this.uploadId]).length);
    //   // if (Object.keys(this.imgUrl[this.uploadId]).length > 0) {
    //   //   return false;
    //   // } else {
    //   //   return true;
    //   // }
    //   console.log(typeof this.imgUrl[this.uploadId]);
    //   return typeof this.imgUrl[this.uploadId] === "undefined" ? true : false;
    // },
  },
  mounted() {
    //this.file.FileValue = this.imgData;
    //console.log(this.$route.params.caseNo);
    console.log(this.imgData);
    console.log(typeof this.imgData);
    console.log(this.imgUrl);
    console.log(this.uploadId);
    console.log(this.disabledUpload);
  },
  methods: {
    resetFile() {
      console.log("reset file");
      this.file = {
        FileName: "",
        FileType: "",
        FileValue: "",
        IsActive: "",
      };
      this.imgUrl[this.uploadId] = "";
    },
    handleImage(e) {
      //console.log(e);
      //console
      if (!this.disabledUpload) {
        if (typeof e != "undefined") {
          this.createBase64Image(e);
        } else {
          this.file = "";
        }
      }
    },
    uploadInputImage(e) {
      console.log(e);
      console.log(typeof e);
      //console.log(e.target.file[0])
      //const uploadImage = e.target.files[0];
      //console.log(this.editedItem.image);
      if (typeof e != "undefined") {
        this.createInputBase64Image();
      } else {
        this.file = "";
      }
    },
    createInputBase64Image() {
      console.log("createInputBase64Image");
      const reader = new FileReader();
      console.log(this.image);
      reader.readAsDataURL(this.image);
      let f = this.image;
      this.file = {
        FileName: f.name,
        FileType: f.type,
        FileValue: null,
        IsActive: true,
      };
      console.log(this.file);
      reader.onload = (e) => {
        this.file.FileValue = e.target.result;
        this.updateUpload();
        //console.log(e.target.result);
      };
      //console.log(reader.readAsDataURL(this.image));
    },
    createBase64Image(e) {
      let files = e.dataTransfer.files;
      //console.log(files);

      let f = files[0];
      let reader = new FileReader(files[0]);

      this.file = {
        FileName: f.name,
        FileType: f.type,
        FileValue: null,
        IsActive: true,
      };
      reader.readAsDataURL(f);

      reader.onload = (e) => {
        this.file.FileValue = e.target.result;
        this.updateUpload();
      };
    },
    updateUpload() {
      console.log(this.file);
      this.$emit("updateUpload", { id: this.uploadId, image: this.file.FileValue });
    },
    removeImage() {
      this.resetFile();
      this.$emit("removeUpload", { id: this.uploadId });
    },
  },
  watch: {
    file: {
      handler() {
        console.log("handler file");
        console.log(this.file);
        if (this.file.FileValue != "") {
          this.imgUrl[this.uploadId] = this.file.FileValue;
          this.isEmptyUrl = false;
        } else {
          this.isEmptyUrl = true;
        }
      },
    },
    imgUrl: {
      handler(value) {
        console.log("imgUrl");
        console.log(value);
        let imgsrc =
          this.url +
          "/public/cases/" +
          this.$route.params.caseNo +
          "/" +
          this.imgUrl[this.uploadId];
        console.log(imgsrc);
        console.log(typeof imgsrc);

        if (this.imgData == "" || this.imgUrl[this.uploadId] != "") {
          this.file.FileValue =
            typeof this.imgUrl[this.uploadId] !== "undefined" &&
            typeof this.url +
              "/public/cases/" +
              this.$route.params.caseNo +
              "/" +
              this.imgUrl[this.uploadId] !==
              "Object"
              ? this.url +
                "/public/cases/" +
                this.$route.params.caseNo +
                "/" +
                this.imgUrl[this.uploadId]
              : this.imgData;
          this.file.FileName = this.uploadName;
          //this.isEmptyUrl = false;
        }
        //console.log(this.file.FileValue);
        if (
          typeof this.imgUrl[this.uploadId] === "undefined" ||
          this.imgUrl[this.uploadId] == ""
        ) {
          this.isEmptyUrl = true;
        }
      },
    },
    imgData: {
      // eslint-disable-next-line no-unused-vars
      handler(value) {
        // console.log(this.$route.params.caseNo);
        console.log("imageData");
        // console.log(typeof value);
        // console.log(this.imgUrl);
        console.log(this.uploadId);
        // console.log(typeof this.imgUrl[this.uploadId]);
        value = value != null ? value : "";
        if (value != "" || this.imgUrl[this.uploadId] != "") {
          this.file.FileValue =
            typeof this.imgUrl[this.uploadId] !== "undefined"
              ? this.url +
                "/public/cases/" +
                this.$route.params.caseNo +
                "/" +
                this.imgUrl[this.uploadId]
              : value;
          this.file.FileName = this.uploadName;
          this.isEmptyUrl = false;
        } else {
          this.isEmptyUrl = true;
        }
        // console.log(this.file);
      },
    },
  },
};
</script>
<style scope>
.upload {
  position: relative;
  width: 90%;
  height: 90%;
  text-align: center;
  padding: 14px;
  display: inline-block;
  border: 1px solid #090909;
  color: #afafaf;
  font-size: 2rem;
  margin: 20px;
  float: left;
  text-align: center;
}

.upload:hover {
  border: 1px solid red;
}

.upload img {
  clear: both;
  max-width: 98%;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.close {
  position: absolute;
  background: red;
  color: white;
  top: -10px;
  right: -10px;
}
</style>
