var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"960px","persistent":""},model:{value:(_vm.attachmentDialog),callback:function ($$v) {_vm.attachmentDialog=$$v},expression:"attachmentDialog"}},[[_c('v-container',[_c('v-card',[_c('v-card-text',[_c('div',[_c('p',[_vm._v("Click on image to select!")])]),_c('v-list',{staticClass:"attachmentTeeth"},[_vm._l((_vm.attachmentCfg.name),function(name,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',{staticClass:"text-left"},[_vm._v(_vm._s(name)+" :")]),_c('v-list-item-subtitle',[_c('div',_vm._l((_vm.attachmentCfg.size),function(size){return _c('img',{key:size,class:_vm.selectedTeeth ==
                        size + '-' + _vm.attachmentCfg.type[index] + '-' + _vm.teethNo + '.png'
                          ? 'selectedTeeth'
                          : '',attrs:{"src":_vm.checkExistTeeth(index, _vm.teethNo, size),"loading":"lazy","alt":""},on:{"click":function($event){return _vm.selectTeeth(
                          'attachments/' +
                            size +
                            '/' +
                            size +
                            '-' +
                            _vm.attachmentCfg.type[index] +
                            '/',
                          size +
                            '-' +
                            _vm.attachmentCfg.type[index] +
                            '-' +
                            _vm.teethNo +
                            '.png'
                        )}}})}),0)])],1)],1)}),_c('v-list-item',[_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',{staticClass:"text-left"},[_vm._v("None :")]),_c('v-list-item-subtitle',[_c('div',[_c('img',{class:_vm.selectedTeeth == 'teeth-' + _vm.teethNo + '.png'
                          ? 'selectedTeeth'
                          : '',attrs:{"src":require('../../assets/' + 'teeth/teeth-' + _vm.teethNo + '.png'),"loading":"lazy","alt":""},on:{"click":function($event){return _vm.selectTeeth('teeth/', 'teeth-' + _vm.teethNo + '.png')}}})])])],1)],1)],2)],1),_c('v-card-text',[_c('ul',_vm._l((_vm.attachmentCfg.color),function(color){return _c('li',{key:color},[_c('div',{staticClass:"box",style:('background-color:' + color.code + ';padding-left:10px')},[_vm._v(" "+_vm._s(color.size)+" ")])])}),0)]),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v("Close")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }